body {
  background: rgba(249,249,249,255)  ;
}

.search_input {
  background: rgba(159,159,159,255);
  border:solid 1px gray;
  color:white;
}

.page_structure {
  background: rgba(117,117,117,255);
}

.search_input::placeholder {
  color:white;
}

.search_input:focus {
  background: rgb(186, 186, 186);
  color: white;
  outline:none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  border:solid 1px rgb(124, 124, 124);
}

.ls {
  min-height: 95vh;
}
.city-ls {
  color: white;
text-align: center;
  width: 40%;
  min-width: 60%;
  background-color:rgba(159,159,159,255);

}
.user-ls{
  color: white;

}

.solid_border {
  border:solid 1px black;

}
.d--1 {
  background-color: red;
  border:dotted 1px black;

}

.d--2 {
  background-color: green;
  border:dotted 1px black;

}



input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  min-width:70px;
  min-height: 70px;
  width:70px;
  height: 70px;
  cursor: pointer;
}

.media_thumbnail {
  min-width:50px;
  min-height: 50px;
  width:50px;
  height: 50px;
}

.media_thumbnail_card {
  min-width:70px;
  min-height: 70px;
  width:70px;
  height: 70px;
  margin: 0 auto;
  border: solid 1px lightgray;
  border-radius: 5%;
  cursor: pointer;
}


.ord_date {
  min-width:90px;
  min-height: 90px;
  width:90px;
  height: 90px;
}

.ord_name {
  min-width:100px;
  width:10px;
}

.ord_num {
  min-width:90px;
  width:90px;
}

.ord_adr {
  min-width:100px;
  width:100px;

}

.ord_media {
  min-width:100%;
  width:100%;
}

.media_l_btn {
  position: absolute;
  left:-25%;
  top:25%;
  z-index:5;
  background: white;
  border: solid 1px lightgray;
  width:40px;
  height:40px;
}

.media_r_btn {
  position: absolute;
  right:-25%;
  top:25%;
  z-index:5;
  background: white;
  border: solid 1px lightgray;
  width:40px;
  height:40px;
}

.media_thmb {
  position: relative;
  cursor: pointer;
  max-width: 100%;
  max-height: 100%;
}

.unsuppported_msg_text {
  width: 70%;
  margin-top: 10%;
  margin-left:  auto;
  margin-right: auto;
  text-align: center;

}

.modal_image_icon_menu a {
  display: inline-block;
  font-size: 40px;
  cursor: pointer;
  line-height: 40px;
  box-sizing: border-box;
  border: none;
  padding: 0px 5px 0px 5px;
  margin-left: 10px;
  color: ;
  background-color: rgba(0, 0, 0, 0);
  z-index: 5004;
  position: relative;
}
#onCloseCross a svg {

  z-index: -1;
}
.onCloseCross a svg path{

  z-index: -1;
}

.modal_image_icon_menu {
  display: inline-block;
  float: right;
  position: relative;
  padding: 10px;
  margin-right: 10px;
}

.modal_image_caption {
  display: inline-block;
  color: rgb(161, 150, 150);
  font-size: 120%;
  padding: 10px;
  margin: 0;

}

.very_small_text {
  font-size: 12px;
}

.small_text {
  font-size: 15px;
}

.top_menu_btn {
  background: lightgray;
  color: black;

  border: solid 1px gray;
}

.top_menu_btn:hover {
  background:white;
}

.order_arrears{
  border: solid 2px black;
  width:90%;
  text-align: center;
  font-size: 25px;
  font-weight: bold ;
}

.order_header {
  border:solid 1px lightgray;
  font-size: 14px;
}

.order_body {
  font-size: 14px;
}

.order_buttons {
  width:90%;
  min-height: 3em;
  max-height: 5em;
  font-size: 12px;
  padding:10px;
  /* word-wrap: normal; */
}

.pdf_button {
  background: url(../assets/pdf.png) no-repeat;
  background-size: 50px;
  width:50px;
  height:50px;
  border:none;
}

.modal_left_button {
  position: absolute;
  z-index: 1;
  border: none;
  background: none;
  padding-top: 20%;
  padding-bottom: 20%;
  padding-left: 3%;
  padding-right: 3%;
}

.modal_right_button {
  position: absolute;
  z-index: 1;
  border: none;
  background: none;
  padding-top: 20%;
  padding-bottom: 20%;
  padding-left: 3%;
  padding-right: 3%;
  right: 0;
}

.modal_right_button:hover {
  /* background: red; */
  background: rgba(255, 255, 255, .6);
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}

.modal_left_button:hover {
  /* background: red; */
  background: rgba(255, 255, 255, .6);
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.2);
}



.modal_image_container {
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  touch-action: none;
}

.modal_image_medium_img {
  max-width: 98%;
  max-height: 98%;
  background-color: white;
}


.modal_image_overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  top:0;
}

.modal_image_content {

    background: white;
    position: absolute;
    left:5vw;
    width: 90vw;
    display: grid;
    align-items: center;
    margin:auto auto;
    border-radius: 5px;
    min-height:  80%;
    max-height:  95%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.modal_image_content::-webkit-scrollbar {
  display: none;
}


.modal_image_header {
  position: static;
  top: 5vh;
  right: 0;
  z-index: 1;

  overflow: hidden;
  cursor:default;
  height: auto;
  border-bottom:solid 1px lightgray;
}

.modal_image_footer {
  position: relative;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  cursor:default;
  border-top:solid 1px lightgray;
}
.modal_galery {
  position: relative;
  z-index: 1;
  width: 90%;
  margin: 0 auto;
  margin-top: 3vh;
  margin-bottom: 5vh;
  white-space:nowrap

}

.modal_image_content_overlay {

  position: relative;
  width: 90vw;
  max-height: 70vh;
  margin: 0 auto;
  display: grid;
  align-items: center;
  overflow: hidden;
}




.modal_image_content_overlay_img {
  top:0;
  position: relative;
  display: block;
  height:70vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
  object-fit:scale-down;
}
.modal_image_content_overlay_video {
  top:0;
  position: relative;
  display: block;
  height:70vh;
  width: auto;
  margin: 0 auto;
  border-radius: 5px;
  object-fit:scale-down;

}

.search_line_input_container {

  margin-bottom:'1vh';

  width: 30vw;
  width: calc(100% - 102px);
  width: -moz-calc(100% - 102px);
  width: -webkit-calc(100% - 152px);

}
.search_line_buttons_container {
  margin-right:'20px';
  margin-bottom:'1vh';
  width: 152px;
  padding: 0;
  float: right;
  /* width: -moz-calc(100vw - 120px);
  width: -webkit-calc(100vw - 120px); */
}

.search_line_buttons_container button {
  float: left;

}


@media (max-width: 576px) {



  .modal_left_button {
    display:block;
    position: absolute;
    z-index: 1;
    border: none;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    padding: 5%;


  }

  .modal_right_button {
    position: absolute;
    z-index: 1;
    border: none;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    padding: 5%;
    right: 0;
  }

  .modal_right_button:hover {
    /* background: red; */
    fill: red;
  }


  .modal_image_container {
    position: fixed;
    z-index: 5000;
    left: 0;
    top: 0;
    bottom:0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    touch-action: none;
  }

  .modal_image_medium_img {
    max-width: 98%;
    max-height: 98%;
    background-color: white;
  }


  .modal_image_overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    top:0;
  }

  .modal_image_content {

      background: white;
      position: absolute;
      left:5vw;
      width: 90vw;
      display: grid;
      align-items: center;
      margin:auto auto;
      border-radius: 5px;
      min-height:  80%;
      max-height:  95%;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

  }

  .modal_image_content::-webkit-scrollbar {
    display: none;
  }


  .modal_image_header {
    position: static;
    top: 5vh;
    right: 0;
    z-index: 1;

    overflow: hidden;
    cursor:default;
    height: auto;
    border-bottom:solid 1px lightgray;
  }

  .modal_image_footer {
    position: relative;
    bottom: 0;
    width: 100%;
    overflow: hidden;
    cursor:default;
    border-top:solid 1px lightgray;
  }
  .modal_galery {
    position: relative;
    z-index: 1;
    width: 90%;
    margin: 0 auto;
    margin-top: 3vh;
    margin-bottom: 3vh;
    white-space:nowrap

  }

  .modal_image_content_overlay {

    position: relative;
    width: 90vw;
    max-height: 50vh;
    top:0;
    display: grid;
    align-items: center;
    overflow: hidden;
  }




  .modal_image_content_overlay_img {
    top:0;
    position: relative;
    display: block;
    height:50vh;
    width: auto;

    border-radius: 5px;
    object-fit:scale-down;
  }
  .modal_image_content_overlay_video {
    position: relative;
    height:50vh;
    max-width: 90%;
    margin: 0 auto;
    border-radius: 5px;

  }
}


 .content_loading {
   width: 50vw;
   height: 60vh;
   margin: 0 auto;
   background-color: none;
   left:0;
   bottom: 0;
   left:0;
   z-index: 5000;
   text-align: center;
 }

.top_side {
  width:100%;
  margin-left: auto;
}

.top_side_user_info {

}

.top_side_button_group {

}

.date_search_input {
  float: right;

  margin:0;
}

.date_search_label {
  float: right;
  color: white;
  background: red;
  right: 0;
}

.date_search_container {

  grid-gap: '20px';
  grid-template-columns: '0.1fr 30px 100px 20px  30px 100px';
  background: red;
}

.qr_modal__header {
  position: static;
  top: 0;
  right: 0;
  z-index: 1;

  overflow: hidden;
  cursor:default;
  height: 1vh;
  border-bottom:solid 1px lightgray;
}

.qr_modal_img {

}



.qr_modal_container {
  position: fixed;
  z-index: 5000;
  left: 0;
  top: 0;
  bottom:0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  touch-action: none;
}




.qr_modal_overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  top:0;text-align: center;

}

.qr_modal_content {

    background: white;
    position: relative;

    width: 330px;
    height: auto;
    display: grid;
    align-items: center;
    margin:0 auto;
    border-radius: 5px;
    padding-bottom: 10px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

}

.qr_modal_content::-webkit-scrollbar {
  display: none;
}


.qr_modal_image__footer {
  position: relative;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  cursor:default;
  border-top:solid 1px lightgray;
}

.qr_modal_media_owerlay {

  position: relative;
  width: auto;
  height: auto;  min-height: 340px;
  margin: 0 auto;
  display: grid;
  align-items: center;
  overflow: hidden;
}


.qr_media_owerlay_img {
  top:0;
  position: relative;
  display: block;

  width: auto;
  height: auto;

  margin: 0 auto;
  border-radius: 5px;
  object-fit:scale-down;
}

.showpass-btn {
  background-color: white;
  border: solid 1px white;
}

.admin_settings_submit_btn {
  width:200px;
  margin:0 auto;
  margin-top:50px
}
